body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

body, html {
  font-family: 'Roboto', sans-serif;
  color: #2a192a; }

h1 {
  margin-top: 0;
  font-family: 'Cinzel', serif;
  font-size: 3vw;
  font-weight: 100;
  margin-bottom: 10px; }
  @media only screen and (max-width: 782px) {
    h1 {
      font-size: 10vw; } }

p {
  font-size: 18px; }
  @media only screen and (max-width: 782px) {
    p {
      font-size: 12px; } }

@media only screen and (max-width: 782px) {
  .slider-control-bottomcenter {
    display: none; } }

h2 {
  font-weight: 300;
  font-size: 2vw; }
  @media only screen and (max-width: 782px) {
    h2 {
      font-size: 2.5vw; } }

a {
  color: #2d5cab; }

.App {
  text-align: center; }

.hide {
  display: none; }

img {
  padding: 10px;
  box-sizing: border-box; }
  @media only screen and (max-width: 782px) {
    img {
      width: 100%; } }

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin); }

.arrow-down {
  position: absolute;
  bottom: 10px;
  right: 30px;
  z-index: 99999;
  width: 100px;
  height: 100px;
  transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
  transition: transform 0.3s, -webkit-transform 0.3s; }
  .arrow-down:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1); }
  .arrow-down.home {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg); }
  .arrow-down svg {
    width: 100px;
    height: 100px;
    fill: rgba(230, 230, 230, 0.7); }

.section {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden; }
  .section.carousel .title {
    position: absolute;
    z-index: 9999;
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.4); }
    .section.carousel .title h1 {
      position: absolute;
      -webkit-transform-origin: 50% 50%;
              transform-origin: 50% 50%;
      position: relative;
      color: #FFFFFF;
      font-size: 4vw; }
      @media only screen and (max-width: 782px) {
        .section.carousel .title h1 {
          font-size: 10vw; } }
  .section .space-below {
    margin-bottom: 60px; }
    @media only screen and (max-width: 782px) {
      .section .space-below {
        margin-bottom: 20px; } }

.App-link {
  color: #61dafb; }

.fp-tableCell {
  height: 100% !important; }

.row {
  display: flex;
  align-items: stretch;
  margin: 0 auto;
  max-width: 1400px;
  text-align: left; }
  @media only screen and (max-width: 782px) {
    .row {
      width: 100%;
      flex-direction: column;
      text-align: center; } }
  .row .col {
    padding: 0 20px;
    flex: 1 1; }
    .row .col.quater {
      flex-basis: 25%; }
    .row .col.three-quaters {
      flex-basis: 75%; }
    .row .col.third {
      flex-basis: 33.3333333%; }
    .row .col.two-thirds {
      flex-basis: 66.6666666%; }
    .row .col img {
      width: 100%;
      height: auto; }

.portrait {
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.4);
  border: 4px;
  border-color: #f4f4f4;
  max-width: 100%; }
  @media only screen and (max-width: 782px) {
    .portrait {
      max-width: 70%; } }

.slider ul {
  display: block;
  list-style-type: disc;
  -webkit-margin-before: 0em;
          margin-block-start: 0em;
  -webkit-margin-after: 0em;
          margin-block-end: 0em;
  -webkit-margin-start: 0px;
          margin-inline-start: 0px;
  -webkit-margin-end: 0px;
          margin-inline-end: 0px;
  -webkit-padding-start: 0px;
          padding-inline-start: 0px; }

.slider-slide {
  width: 100% !important;
  height: 80vh !important; }
  .slider-slide .img {
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    background-color: #f0f0f0;
    width: 100% !important;
    height: 80vh !important; }

.carousel .slider-slide {
  width: 100vw !important;
  height: 100vh !important; }
  .carousel .slider-slide:nth-child(odd) .img {
    -webkit-animation: kenburns_odd 40s infinite;
            animation: kenburns_odd 40s infinite; }
  .carousel .slider-slide:nth-child(even) .img {
    -webkit-animation: kenburns_even 40s infinite;
            animation: kenburns_even 40s infinite; }
  .carousel .slider-slide .img {
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-color: #f0f0f0;
    width: 100vw !important;
    height: 100vh !important; }

@-webkit-keyframes kenburns_odd {
  0% {
    -webkit-transform: scale3d(1, 1, 1);
            transform: scale3d(1, 1, 1);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in; }
  50% {
    -webkit-transform: scale3d(1.5, 1.5, 1.5);
            transform: scale3d(1.5, 1.5, 1.5); }
  100% {
    -webkit-transform: scale3d(1, 1, 1);
            transform: scale3d(1, 1, 1); } }

@keyframes kenburns_odd {
  0% {
    -webkit-transform: scale3d(1, 1, 1);
            transform: scale3d(1, 1, 1);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in; }
  50% {
    -webkit-transform: scale3d(1.5, 1.5, 1.5);
            transform: scale3d(1.5, 1.5, 1.5); }
  100% {
    -webkit-transform: scale3d(1, 1, 1);
            transform: scale3d(1, 1, 1); } }

@-webkit-keyframes kenburns_even {
  0% {
    -webkit-transform: scale3d(1.5, 1.5, 1.5);
            transform: scale3d(1.5, 1.5, 1.5);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out; }
  50% {
    -webkit-transform: scale3d(1, 1, 1);
            transform: scale3d(1, 1, 1); }
  100% {
    -webkit-transform: scale3d(1.5, 1.5, 1.5);
            transform: scale3d(1.5, 1.5, 1.5); } }

@keyframes kenburns_even {
  0% {
    -webkit-transform: scale3d(1.5, 1.5, 1.5);
            transform: scale3d(1.5, 1.5, 1.5);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out; }
  50% {
    -webkit-transform: scale3d(1, 1, 1);
            transform: scale3d(1, 1, 1); }
  100% {
    -webkit-transform: scale3d(1.5, 1.5, 1.5);
            transform: scale3d(1.5, 1.5, 1.5); } }

