$mybez: cubic-bezier(0.650, 0.010, 1.000, 0);
$mybez2: cubic-bezier(0.165, 0.840, 0.440, 1.000);
$easeOutExpo: cubic-bezier(0.190, 1.000, 0.220, 1.000);



body,html{
  font-family: 'Roboto', sans-serif;
  color: #2a192a;
}
h1{
  margin-top:0;
  font-family: 'Cinzel', serif;
  font-size: 3vw;
  font-weight: 100;
  margin-bottom:10px;
  @media only screen and (max-width : 782px) {
    font-size: 10vw;
  }
}
p{
  font-size: 18px;
  @media only screen and (max-width : 782px) {
    font-size: 12px;
  }
}
.slider-control-bottomcenter{
  @media only screen and (max-width : 782px) {
    display:none;
  }
}
h2{
  font-weight: 300;
  font-size: 2vw;
  @media only screen and (max-width : 782px) {
    font-size: 2.5vw;
  }
}
a{
  color:#2d5cab;
}
.App {
  text-align: center;
}
.hide{
  display: none;
}
img{

  @media only screen and (max-width : 782px) {
    width:100%;
  }
  padding: 10px;
  box-sizing: border-box;
}


.App-header {
  //background-color: rgb(242,241,220);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}
.arrow-down{
  position: absolute;
  bottom:10px;
  right: 30px;
  z-index: 99999;
  width:100px;
  height:100px;
  transition: transform 0.3s;
  &:hover{
    transform: scale(1.1);
  }
  &.home{
    transform: rotate(180deg);
  }
  svg{
    width:100px;
    height:100px;
    fill:rgba(230,230,230,0.7);
  }
}
.section{

  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  &.carousel{
    .title{
      position: absolute;
      z-index: 9999;
      width:100vw;
      height:100vh;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      background-color: rgba(0,0,0,0.4);
      h1{
        position: absolute;
        transform-origin: 50% 50%;
        position: relative;
        color:#FFFFFF;
        font-size: 4vw;
        @media only screen and (max-width : 782px) {
          font-size: 10vw;
        }
      }
    }
  }
  //background-color: rgb(222,221,200);
  .space-below{
    margin-bottom:60px;
    @media only screen and (max-width : 782px) {
      margin-bottom:20px;
    }
  }

  
}


.App-link {
  color: #61dafb;
}
.fp-tableCell {
  height: 100% !important;
}
.row{
  display: flex;
  align-items: stretch;
  margin:0 auto;
  max-width:1400px;
  text-align: left;
  @media only screen and (max-width : 782px) {
    width:100%;
    flex-direction: column;
    text-align: center;
  }
  .col{
    padding: 0 20px;
    flex:1;
    &.quater{
      flex-basis:25%;
    }
    &.three-quaters{
      flex-basis:75%;
    }
    &.third{
      flex-basis:33.3333333%;
    }
    &.two-thirds{
      flex-basis:66.6666666%;
    }
    img{
      width:100%;
      height:auto;
    }
  }
}


.portrait{
  box-shadow: 0px 0px 10px rgba(0,0,0,0.4);;
  border:4px;
  border-color: rgb(244,244,244);
  max-width: 100%;
  @media only screen and (max-width : 782px) {
    max-width: 70%;
  }
  //transform: rotate(-3deg);
}
.slider{
  ul {
    display: block;
    list-style-type: disc;
    margin-block-start: 0em;
    margin-block-end: 0em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 0px;
  }
}
.slider-slide{
  width:100% !important;
  height:80vh!important;
  .img{
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    background-color: rgb(240,240,240);
    width:100% !important;
    height:80vh!important;
  }
}
.carousel .slider-slide{
  width:100vw !important;
  height:100vh !important;
  &:nth-child(odd){
    .img{
      animation: kenburns_odd 40s infinite;
    }
  }
  &:nth-child(even){
    .img{
      animation: kenburns_even 40s infinite;
    }
  }
  .img{
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-color: rgb(240,240,240);
    width:100vw !important;
    height:100vh !important;
  }
}
@keyframes kenburns_odd {
  0% {
      transform: scale3d(1, 1, 1);
      animation-timing-function: ease-in;
  }
  50% {
    transform: scale3d(1.5, 1.5, 1.5);
  }
  100% {
    transform: scale3d(1, 1, 1);
  }
}
@keyframes kenburns_even {
  0% {
    transform: scale3d(1.5, 1.5, 1.5);
    animation-timing-function: ease-out;
  }
  50% {
    transform: scale3d(1, 1, 1);
  }
  100% {
    transform: scale3d(1.5, 1.5, 1.5);
  }
}
.slide-visible{

}

